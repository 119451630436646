<template>
  <div>
    <v-container v-if="isLoginStep" id="login-container">
      <v-card class="login-card pa-1" flat outlined dark color="transparent">
        <v-card-text>
          <div class="layout column align-center">
            <div class="v-card__title justify-center display-1 mb-2">
              <v-img :src="logo" max-height="120" max-width="120" />
            </div>
            <div class="days-free-trial-title"><span>Get Started with 7 days free trial</span></div>
            <div v-if="isLogin" class="justify-center text-none sign-login-title"><h1>Your pass to all streaming services</h1></div>
            <div v-else class="v-card__subtitle justify-center display-2 mb-2 text-none sign-login-title">Register</div>
          </div>
        </v-card-text>
        <div class="third-box">
          <v-btn block depressed medium class="text-none" color="white" style="color:black;" :disabled="disabledLogin" :loading="googleLoading" @click="googleLogin">
            <v-icon left color="" class="social-icon">
              mdi-google
            </v-icon>
            <span class="social-text google-text">{{ buttonText }} Continue with Google</span>
          </v-btn>
          <div class="spacer-box" />
          <v-btn block depressed medium class="text-none fb-btn" :disabled="disabledLogin" :loading="facebookLoading" color="" @click="facebookLogin">
            <v-icon left color="white" class="social-icon">
              mdi-facebook
            </v-icon>
            <span class="social-text">{{ buttonText }} Continue with Facebook</span>
          </v-btn>
          <div class="spacer-box" />
          <v-btn block depressed medium class="text-none apple-btn" :disabled="disabledLogin" :loading="appleLoading" @click="appleLogin">
            <v-icon left color="white" class="social-icon">
              mdi-apple
            </v-icon>
            <span class="social-text">{{ buttonText }} Continue with Apple</span>
          </v-btn>
          <div class="spacer-box" />
          <div class="dividing-line">— OR —</div>
          <div class="spacer-box" />
          <v-btn block depressed medium class="text-none login-with-email" :disabled="disabledLogin" :loading="emailLoading" color="" @click="emailLogin">
            <v-icon left color="white" class="social-icon">
              mdi-email
            </v-icon>
            <span class="social-text">{{ buttonText }} Continue with Email</span>
          </v-btn>
          <div class="login-comment-box">
            <div>No Commitments</div>
            <div>No Credit Card Required</div>
          </div>
        </div>
      </v-card>
    </v-container>
    <sign-up v-else ref="signup" :email="extend_params.email" :agree-handle="userLogin" />
    <v-dialog
      v-model="emailNotify"
      max-width="450"
      height="390"
      style="background: #1E1E1E!important;"
    >
      <div class="email-auth-box"><email-auth v-if="emailNotify" :go-back="goBack" :firebase-login="firebaseLogin" /></div>
    </v-dialog>
    <emailVerifyDialog ref="emailVerifyDialog" @ok-handle="emailVerifyOKHandle" />
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import logo from '@/assets/logo1.png'
import SignUp from './signup.vue'
import { getQueryVariable, getGAClientID } from '@/util/index'
import { userReport } from '@/api/user/index.js'
import EmailAuth from './emailauth'
import Cookies from 'js-cookie'
import emailVerifyDialog from './components/overDialog.vue'

export default {
  name: 'Login',
  components: {
    SignUp,
    EmailAuth,
    emailVerifyDialog
  },
  data: () => ({
    current_op: 'login',
    logo: logo,
    isLogin: true,
    loading: false,
    facebookLoading: false,
    googleLoading: false,
    appleLoading: false,
    emailLoading: false,
    emailNotify: false,
    loginForm: {
      username: '',
      password: ''
    },
    extend_params: {
      email: '',
      is_privacy_agreement: true,
      is_receive_message: true
    },
    emailVerified: false,
    loginPlatform: '',
    id_token: ''
  }),
  computed: {
    disabledLogin() {
      return this.appleLoading || this.googleLoading || this.facebookLoading
    },
    isLoginStep() {
      return this.current_op === 'login'
    },
    buttonText() {
      return this.isLogin ? '' : ''
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    window.Intercom('boot', {
      app_id: 'ei6khlkl'
    })

    var current_platform = window.location.hash

    if (!current_platform) {
      return
    }

    if (current_platform.includes('google')) {
      this.googleLoading = true
      this.loginPlatform = 'Google'
    } else if (current_platform.includes('facebook')) {
      this.facebookLoading = true
      this.loginPlatform = 'Facebook'
    } else if (current_platform.includes('apple')) {
      this.appleLoading = true
      this.loginPlatform = 'Apple'
    } else {
      return
    }

    firebase.auth().getRedirectResult().then(async(result) => {
      if (result.credential) {
        var auth = await firebase.auth().signInWithCredential(result.credential)
        var tokenResult = await auth.user.getIdTokenResult(true)
        this.id_token = tokenResult.token
        this.extend_params.email = result.user.email
        this.emailVerified = result.user.emailVerified
        await this.userLogin({})
      } else {
        this.setLoading(false)
      }
    }).catch((error) => {
      this.setLoading(false)
      console.log('credential:' + error)
    }).finally(f => {
      this.setLoading(false)
      window.location.hash = ''
    })

    const onelinkGenerator = new window.AF.OneLinkUrlGenerator(
      { oneLinkURL: 'https://onechannel.onelink.me/cjtv',
        pidKeysList: ['af_pid', 'utm_source'],
        campaignKeysList: ['af_c', 'utm_campaign'],
        pidStaticValue: 'website',
        campaignStaticValue: 'web_app'
      })
    const url = onelinkGenerator.generateUrl()
    if (url) { this.$store.dispatch('user/updateAppDownloadUrl', url) }
  },
  mounted() {
  },
  methods: {
    login() {

    },
    googleLogin() {
      if (this.googleLoading) {
        return
      }
      window.location.hash = 'google'
      this.googleLoading = true
      this.loginPlatform = 'Google'
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    facebookLogin() {
      if (this.facebookLoading) {
        return
      }
      window.location.hash = 'facebook'
      this.facebookLoading = true
      this.loginPlatform = 'Facebook'
      var provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    appleLogin() {
      if (this.appleLoading) {
        return
      }

      window.location.hash = 'apple'
      this.appleLoading = true
      this.loginPlatform = 'Apple'
      var provider = new firebase.auth.OAuthProvider('apple.com')
      firebase.auth().signInWithRedirect(provider)
    },
    emailLogin() {
      this.emailNotify = true
      // var utm = { 'utm_source': getQueryVariable('utm_source'), 'utm_medium': getQueryVariable('utm_medium'), 'utm_campaign': getQueryVariable('utm_campaign') }
      // this.$router.push({ path: '/auth/emailauth', query: utm.utm_source ? utm : {}})
    },
    userLogin(params, op, isEmailAuth) {
      var utm = { 'utm_source': getQueryVariable('utm_source'), 'utm_medium': getQueryVariable('utm_medium'), 'utm_campaign': getQueryVariable('utm_campaign') }
      return new Promise((resolve, reject) => {
        this.$store.dispatch('user/login', { id_token: this.id_token, ...params }).then(data => {
          this.emailNotify = false
          if (op === 'sign_up') {
            userReport({ 'utm': utm, 'client_id': getGAClientID() })
            // this.$store.dispatch('user/logEvent', { eventName: op, params: { method: this.loginPlatform }})
            this.$router.push({ name: 'subscription', query: utm.utm_source ? utm : {}})
            return
          }
          if ('is_privacy_agreement' in data) {
            // email verify when first sign_up
            if (isEmailAuth && !this.emailVerified) {
              this.$refs.emailVerifyDialog.controlDialog(true, `One Channel has sent you an email for verification. 
                      You need to verify the email address before logging in your account.`, 'Email verification', true)
              return
            }
            this.current_op = 'sign_up'
            return
          }

          userReport({ 'utm': utm, 'client_id': getGAClientID() })

          this.$store.dispatch('user/logEvent', { eventName: 'login', params: { method: this.loginPlatform }})

          if (getQueryVariable('redirect')) {
            this.$router.push({ path: decodeURIComponent(getQueryVariable('redirect')), query: utm.utm_source ? utm : {}})
            return
          }

          this.$router.push({ path: '/auth/mychannel', query: utm.utm_source ? utm : {}})

          resolve(true)
        }, () => {
          reject()
        }).finally(() => {
          if (Cookies.get('utm_source')) {
            return
          }
          if (utm.utm_source) {
            Cookies.set('utm_source', utm.utm_source)
          }

          if (utm.utm_medium) {
            Cookies.set('utm_medium', utm.utm_medium)
          }

          if (utm.utm_campaign) {
            Cookies.set('utm_campaign', utm.utm_campaign)
          }
        })
      })
    },
    switchHandle() {
      this.isLogin = !this.isLogin
    },
    setLoading(value) {
      if (this.loginPlatform === 'Google') {
        this.googleLoading = value
        return
      }

      if (this.loginPlatform === 'Facebook') {
        this.facebookLoading = value
        return
      }

      if (this.loginPlatform === 'Apple') {
        this.appleLoading = value
        return
      }
    },
    goBack() {
      this.emailNotify = false
    },
    setLoginInfo(id_token) {

    },
    emailVerifyOKHandle() {
      this.$refs.emailVerifyDialog.controlLoading(false)
      this.$refs.emailVerifyDialog.controlShowDialog(false)
    },
    async firebaseLogin(userCredential) {
      // var auth = await firebase.auth().signInWithCredential(userCredential)
      // var tokenResult = await auth.user.getIdTokenResult(true)
      // console.log(tokenResult)

      this.id_token = await firebase.auth().currentUser.getIdToken(true)
      this.extend_params.email = userCredential.user.email
      this.emailVerified = userCredential.user.emailVerified
      await this.userLogin({}, null, true)
    }
  }
}
</script>
<style scoped lang="scss">
#login-container{
  letter-spacing: 0.05em;
  max-width:400px;
  .v-card__subtitle{
    margin-top: 10px;
    font-size: 12px;
  }
  .spacer-box{
    height: 20px;
  }

  .fb-btn{
    background: #3b5998!important;
  }

  .login-with-email{
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 1px transparent;
    background-image: linear-gradient(rgba(0,0,0, 0), rgba(0,0,0, 0)), linear-gradient(48deg, #6d7cff, #ff51ff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px black inset!important;
  }
  .social-icon{
    position: absolute;
    left: 1%;
    font-size: 28px;
  }
  .dividing-line{
    // font-style: italic;
    color: #eae5e5;
    text-align: center;
    font-size: smaller;
  }

  .sign-login-title{
    text-align:center;
    color:white;
    font-weight: bold;
    line-height: initial;
  }

  .social-text{
      letter-spacing: 0.03em;
      font-size: 14px !important;
      font-weight: bold !important;
  }

  .google-text{
        color: #4a4a4a!important;
  }

}
.notify-box{
    padding: 20px 20px 0px 20px;
}

.v-card__subtitle, .v-card__text, .v-card__title{
  padding: unset!important;
}

.sign-login-title{
  padding-bottom: 18px;
}

.days-free-trial-title{
  color:#83ff00;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
}

.login-comment-box{
  margin-top: 16px;
  text-align: center;
  font-size:12px;
}

.email-auth-box{
  // padding: 20px;
}

// .theme--dark.v-btn.v-btn--has-bg {
//     background-color: black;
// }

</style>
